export default [
  {
    title: lang("home"),
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: lang("devices"),
    route: 'devices',
    icon: 'ServerIcon',
  },
  {
    title: lang("users"),
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: lang("receipts"),
    route: 'orders',
    icon: 'CalendarIcon',
  },
]
